import styled from "styled-components";

export const EventsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #111E12;

  padding: 20px;
  min-height: 100vh;

  font-family: 'Montserrat';

  .text-muted {
    margin-bottom: 0px !important;
  }

  .pagination {
    margin-bottom: 20px;
  }

  .mobile-only {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }

  .desktop-only {
    display: block;
    @media (max-width: 768px) {
      display: none;
    }
  }
`

export const Title = styled.h1`
  margin-bottom: 30px;
  font-family: 'Montserrat';
  font-weight: bold;
  margin-bottom: 0;
  letter-spacing: -0.04em;
  padding-bottom: 40px;
  width: 100%;

  font-size: 30px;
  align-self: flex-start;

  @media (max-width: 768px) {
    padding: 10px 0px 10px 10px;
  }
`;

export const Subcontainer = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 1600px;
  width: 100%;
  margin: auto;
  margin-top: 30px;

  @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
`;

export const ChartContainer = styled.div`
  background-color: #1d2d1d;
  padding: 20px 20px 0px 20px;
  border-radius: 10px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    padding: 5px;
    width: 100%;
  }

  .recharts-surface {
    @media (max-width: 768px) {
      margin-left: -20px;
    }
  }

  .page-link {
    background: #111E12;
  }
`;

export const MainHeader = styled.div`
`

export const VideosHeader = styled.div`
    max-width: 100%;
`

export const HeaderPupsikContainer = styled.div`
  display: flex;
  margin-top: 20px; 
  flex-direction: row;
  justify-content: center;
  gap: 12px;
`

export const HeaderPupsikSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1px;
  align-items: end;
  background-color: #564e4e;
  padding: 5px;
  border-radius: 5px;
`

export const SportIcon = styled.img`
  padding-right: 10px;
  height: 20px;
  padding-right: 10px;
`
export const LeagueName = styled.a`
  color: #758b77;

  : hover {
    color: gray;
    text-decoration: none;
  }
`

export const LegendContainer = styled.div`
  display: flex;
  align-items: center; 
  padding: 8px 12px;
  cursor: pointer;
  background-color: ${props => props.selected ? '#E9FD8C' : 'transparent'};
  border: none;
  color: ${props => props.selected ? '#111E12' : '#1d2d1d'};
  margin: 0;

  :hover {
    background: #E9FD8C;
    color: #1d2d1d;
  }
  
  @media (max-width: 768px) {
    padding: 6px 10px;
  }
`

export const TotalEventsContainer = styled.div`
  font-size: 25px;
  justify-self: flex-start;
`

export const TooltipContainer = styled.div`
  background-color: #111E12;
  color: white;
  padding: 10px;
  border-radius: 5px;
  width: 150px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`

export const TooltipItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  gap: 5px;
`

export const TooltipLabel = styled.div`
  color: white;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
`

export const TooltipItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const TooltipIcon = styled.div`
  background-color: ${props => props.color};
  width: 2px;
  height: 10px;
  border-radius: 2px;
`

export const TooltipText = styled.div`
  color: white;
`

export const TooltipValue = styled.div`
  color: #b8dcef;
  font-weight: bold;
`

export const TooltipSeparator = styled.div`
  color: white;
  width: 100%;
  height: 1px;
  background-color: rgb(255, 255, 255, 0.1);
  margin-top: 5px;

`

export const TooltipTotalContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 3px;
`

export const TooltipTotal = styled.div`
  color: white;
  font-weight: bold;
  font-size: 15px;
`

export const TooltipTotalValue = styled.div`
  color: #e9fd8c;
  font-weight: bold;
  font-size: 15px;
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }
`

export const RightContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`

export const SportsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #1d2d1d;
  padding: 11px;
  border-radius: 5px;
  justify-content: center;

  @media (max-width: 768px) {
    width: 100%;
    order: 2;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`

export const NameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #1d2d1d;
  padding: 10px;
  border-radius: 5px;

  @media (max-width: 768px) {
    width: 100%;
    order: 1;
  }
`

export const NameSearchContainer = styled.div`
  min-width: 200px;

  @media (max-width: 768px) {
    width: 100%;
  }

  input {
    background-color: #1d2d1d;
    border: 0px;
    color: white;
    height: 38px;

    ::placeholder {
      color: white;
      opacity: 0.6;
    }
  }
`

export const LiquidityButtonContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #1d2d1d;
  padding: 11px;
  border-radius: 5px;
  margin-right: 10px;

  @media (max-width: 768px) {
    background-color: transparent;
    padding: 0;
    margin-right: 0;
    margin-left: 5px;
    margin-top: 0;
    order: 2;
  }
`

export const LiquidityButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.active ? '#E9FD8C' : 'transparent'};
  color: ${props => props.active ? '#111E12' : 'white'};
  border: none;
  border-radius: 3px;
  padding: 6px 12px;
  font-family: 'Montserrat';
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  cursor: pointer;
  transition: all 0.2s ease;
  height: 38px;

  &:hover {
    background-color: ${props => props.active ? '#E9FD8C' : 'rgba(233, 253, 140, 0.2)'};
  }

  @media (max-width: 768px) {
    width: auto;
    height: 36px;
    padding: 4px 8px;
  }
`

export const EventsGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 20px 0;
  width: 100%;
`;

export const EventCard = styled.div`
  background-color: #1d2d1d;
  border-radius: 10px;
  padding: 20px;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  width: 100%;
  position: relative;
  
  ${props => props.isUpcoming && `
    background-color: rgba(184, 220, 239, 0.03);
    border-left: 3px solid #B8DCEF;
    padding-left: 17px;  /* 20px - 3px to account for the border */
  `}

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
  }
`;

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  min-width: 150px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 5px;
`;

export const CardSportIcon = styled.img`
  width: 24px;
  height: 24px;
  filter: ${props => props.filter};
`;

export const CardSportName = styled.span`
  font-weight: bold;
  color: #E9FD8C;
`;

export const CardEventName = styled.div`
  font-size: 16px;
  color: white;
`;

export const CardLeagueName = styled.div`
  font-size: 14px;
  color: #758b77;
`;

export const CardTime = styled.div`
  font-size: 14px;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  min-width: 150px;
  justify-content: flex-end;

  @media (max-width: 768px) {
    justify-content: flex-start;
    width: 100%;
  }
`;

export const CardTimeIcon = styled.i`
  color: #E9FD8C;
`;

export const GroupButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.active ? '#E9FD8C' : 'transparent'};
  color: ${props => props.active ? '#111E12' : 'white'};
  border: none;
  border-radius: 3px;
  padding: 6px 12px;
  font-family: 'Montserrat';
  font-weight: ${props => props.active ? 'bold' : 'normal'};
  cursor: pointer;
  transition: all 0.2s ease;
  height: 38px;
  margin-right: 10px;

  &:hover {
    background-color: ${props => props.active ? '#E9FD8C' : 'rgba(233, 253, 140, 0.2)'};
  }

  @media (max-width: 768px) {
    width: auto;
    height: 36px;
    padding: 4px 8px;
  }
`;

export const LeagueGroup = styled.div`
  margin-bottom: 20px;
  border-radius: 10px;
  overflow: hidden;
`;

export const LeagueHeader = styled.div`
  background-color: #1d2d1d;
  padding: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: #2d3d2d;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
`;

export const LeagueGroupName = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #E9FD8C;
  display: flex;
  align-items: center;
  gap: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const LeagueCount = styled.div`
  font-size: 14px;
  color: #758b77;
  margin-left: auto;

  @media (max-width: 768px) {
    margin-left: 0;
    margin-top: 5px;
  }
`;

export const LeagueEvents = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #1d2d1d;
  overflow: hidden;
`;

export const TimelineSeparator = styled.div`
  position: relative;
  width: 100%;
  height: 2px;
  background-color: #B8DCEF;
  margin: 20px 0;
`;

export const TimelineTag = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #B8DCEF;
  color: #111E12;
  padding: 5px 15px;
  border-radius: 15px;
  font-size: 14px;
  font-weight: bold;
  white-space: nowrap;
`;