import React, { useEffect, useState, useRef, useCallback, useMemo } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
  Cell,
  CartesianGrid
} from "recharts";
import { useTranslation } from 'react-i18next';
import { NavBarMain } from "..";
import { Medal } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";

import "./Events.styled";
import {
  EventsContainer,
  Title,
  Subcontainer,
  SportIcon,
  LeagueName,
  LegendContainer,
  ChartContainer,
  TotalEventsContainer,
  TooltipContainer,
  TooltipItem,
  TooltipLabel,
  TooltipItemContainer,
  TooltipIcon,
  TooltipText,
  TooltipValue,
  TooltipSeparator,
  TooltipTotalContainer,
  TooltipTotal,
  TooltipTotalValue,
  FilterContainer,
  RightContainer,
  SportsContainer,
  NameContainer,
  NameSearchContainer,
  LiquidityButtonContainer,
  LiquidityButton,
  EventsGrid,
  EventCard,
  CardHeader,
  CardSportIcon,
  CardSportName,
  CardEventName,
  CardLeagueName,
  CardTime,
  CardTimeIcon,
  CardContent,
  GroupButton,
  LeagueGroup,
  LeagueHeader,
  LeagueGroupName,
  LeagueCount,
  LeagueEvents,
  TimelineSeparator,
  TimelineTag
} from "./Events.styled";
import { useEvents } from "../../hooks";
import { Table, Pagination, Form } from "react-bootstrap";
import { getSportIcon, getSportIconColor } from "../../utils/events";
import { checkIfTokenStillValid } from "../../repositories/utils";

const HOUR_MS = 60 * 60 * 1000;

const hoursData = Array.from({ length: 24 }, (_, i) => ({ name: i }));

const getCapitalizedWord = (word) => {
  return word.charAt(0).toUpperCase() + word.slice(1);
};

const leagueVariants = {
  hidden: { 
    height: 0,
    opacity: 0,
    transition: {
      height: { duration: 0.2, ease: "easeOut" },
      opacity: { duration: 0.15 }
    }
  },
  visible: { 
    height: "auto",
    opacity: 1,
    transition: {
      height: { duration: 0.2, ease: "easeOut" },
      opacity: { duration: 0.15, delay: 0.05 }
    }
  }
};

// Move getSortedLeagues outside the component to prevent recreation
const getSortedLeaguesFromData = (data) => {
  if (!data) return [];
  
  // Group events by league and calculate average liquidity
  const groupedEvents = data.reduce((acc, event) => {
    const leagueName = event.league.name;
    if (!acc[leagueName]) {
      acc[leagueName] = {
        events: [],
        totalLiquidity: 0,
        count: 0
      };
    }
    acc[leagueName].events.push(event);
    acc[leagueName].totalLiquidity += event.liquidityScore || 0;
    acc[leagueName].count += 1;
    return acc;
  }, {});

  // Sort leagues by average liquidity score
  return Object.entries(groupedEvents)
    .map(([leagueName, data]) => {
      // Find the most common sport in the league
      const sportCounts = data.events.reduce((acc, event) => {
        acc[event.sport.name] = (acc[event.sport.name] || 0) + 1;
        return acc;
      }, {});
      const mostCommonSport = Object.entries(sportCounts)
        .sort((a, b) => b[1] - a[1])[0][0];

      return {
        leagueName,
        events: data.events,
        averageLiquidity: data.totalLiquidity / data.count,
        mostCommonSport
      };
    })
    .sort((a, b) => b.averageLiquidity - a.averageLiquidity);
};

export const Events = ({ history }) => {
  const { getUpcomingEvents } = useEvents();
  const [events, setEvents] = useState({});
  const [activePage, setActivePage] = useState(1);
  const [pages, setPages] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [selectedSports, setSelectedSports] = useState(["Football", "Basketball", "Tennis", "Baseball", "Hockey", "AmericanFootball"]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [filterText, setFilterText] = useState("");
  const [liquidityFilter, setLiquidityFilter] = useState(false);
  const [filterBy, setFilterBy] = useState('days');
  const [displayedEvents, setDisplayedEvents] = useState(50);
  const observer = useRef();
  const { t } = useTranslation();
  const [groupByLeague, setGroupByLeague] = useState(false);
  const [expandedLeagues, setExpandedLeagues] = useState(new Set());
  const expandTimeoutRef = useRef(null);
  const debounceTimeoutRef = useRef(null);
  const isManualToggle = useRef(false);
  const filterChangeRef = useRef(false);

  const filterOptions = [
    { value: 'days', label: t('days') },
    { value: 'hours', label: t('hours') },
  ];

  const visibleData = (pages || [])[activePage - 1]?.filter(
    ({ sport, name, league, liquidityScore }) => 
    (selectedSports.length === 6 || selectedSports.includes(getCapitalizedWord(sport.name))) && 
    (name.toLowerCase().includes(filterText.toLowerCase()) || (league?.name || '').toLowerCase().includes(filterText.toLowerCase())) &&
    (!liquidityFilter || (liquidityScore >= 6.5))
  );

  const lastEventElementRef = useCallback(node => {
    if (observer.current) observer.current.disconnect();
    observer.current = new window.IntersectionObserver(entries => {
      if (entries[0].isIntersecting && visibleData?.length > displayedEvents) {
        setDisplayedEvents(prev => Math.min(prev + 50, visibleData.length));
      }
    });
    if (node) observer.current.observe(node);
  }, [visibleData, displayedEvents]);

  useEffect(() => {
    async function validateToken() {
      await checkIfTokenStillValid(history);
    }
    validateToken();
  }, [history]);

  const CustomLegend = ({ payload, onClick }) => {
    // Get all sports that have events on the selected date
    const sportsWithData = Object.keys(
      sortedData.reduce((acc, data) => {
        Object.keys(data).forEach(key => {
          if (key !== 'dateTime' && key !== 'name' && data[key] > 0) {
            acc[key] = true;
          }
        });
        return acc;
      }, {})
    );

    const filteredPayload = payload.filter(entry => sportsWithData.includes(entry.value));

    return (
      <div style={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center',
        marginTop: '20px',
        marginBottom: '10px'
      }}>
        <div style={{
          display: 'flex',
          background: '#FFFFFF',
          borderRadius: '5px',
          overflow: 'hidden'
        }}>
          {filteredPayload.map((entry, index) => (
            <LegendContainer 
              key={`item-${index}`} 
              onClick={() => onClick(entry.value)}
              selected={selectedSports.includes(entry.value)}
            >
              <img 
                src={getSportIcon(entry.value.toLowerCase())} 
                alt={entry.value}
                style={{ 
                  width: '24px', 
                  height: '24px',
                  filter: getSportIconColor(entry.value.toLowerCase())
                }}
              />
            </LegendContainer>
          ))}
        </div>
      </div>
    );
  };

  const data = Object.values(events)
    .flatMap((events) =>
      events.map(({ name, sport, startedAt, liquidityScore }) => ({ name, sport, startedAt, liquidityScore }))
    )
    .sort((a, b) => new Date(a.startedAt) - new Date(b.startedAt));

    useEffect(() => {
      const currentTime = Date.now();
      const data = Object.values(events)
        .flatMap((events) =>
          events.map(({ name, sport, league, startedAt, liquidityScore }) => ({ name, sport, league, startedAt, liquidityScore }))
        )
        .filter(
          ({ startedAt }) =>
            currentTime - new Date(startedAt) <= 3 * 60 * 60 * 1000
        )
        .sort((a, b) => new Date(a.startedAt) - new Date(b.startedAt));
    
      let startTimes;
      let intervalCount;
      let currentPage;
      let minTime;
      let maxTime;
    
      if (filterBy === 'hours') {
        startTimes = data.map(({ startedAt }) => {
          const time = new Date(startedAt);
          time.setMinutes(0);
          time.setSeconds(0);
          time.setMilliseconds(0);
          return time.getTime();
        });
        minTime = Math.min(...startTimes);
        maxTime = Math.max(...startTimes);
        intervalCount = Math.ceil((maxTime - minTime) / HOUR_MS);
    
        let now = Date.now();
        currentPage = Math.floor((now - minTime) / HOUR_MS) + 1;
        currentPage = Math.max(1, Math.min(currentPage, intervalCount));
      } else {
        startTimes = data.map(({ startedAt }) => {
          const time = new Date(startedAt);
          time.setHours(0);
          time.setMinutes(0);
          time.setSeconds(0);
          time.setMilliseconds(0);
          return time.getTime();
        });
        minTime = Math.min(...startTimes);
        maxTime = Math.max(...startTimes);
        intervalCount = Math.ceil((maxTime - minTime) / (24 * HOUR_MS));
    
        let now = Date.now();
        currentPage = Math.floor((now - minTime) / (24 * HOUR_MS)) + 1;
        currentPage = Math.max(1, Math.min(currentPage, intervalCount));
      }
    
      const pages = [];
      for (let i = 0; i < intervalCount; i++) {
        const startTime = new Date(minTime + i * (filterBy === 'hours' ? HOUR_MS : 24 * HOUR_MS));
        const endTime = new Date(minTime + (i + 1) * (filterBy === 'hours' ? HOUR_MS : 24 * HOUR_MS));
        const pageData = data.filter(({ startedAt }) => {
          const time = new Date(startedAt).getTime();
          return time >= startTime.getTime() && time < endTime.getTime();
        });
        pages.push(pageData);
      }
    
      setPages(pages);
      setActivePage(currentPage);
    }, [events, filterBy]);

  const handleSportClick = (sport) => {
    setSelectedSports((prevSports) => {
      if (prevSports.length === 1 && prevSports.includes(sport)) {
        // If only one sport is selected and it is the clicked sport, select all sports
        return ["Football", "Basketball", "Tennis", "Baseball", "Hockey", "AmericanFootball"];
      } else if (prevSports.length === 0 || prevSports.length === 6) {
        // If no sports or all sports are selected, select only the clicked sport
        return [sport];
      } else if (!prevSports.includes(sport)) {
        // If the clicked sport is not selected, add it to the array
        return [...prevSports, sport];
      } else {
        // If the clicked sport is already selected, remove it from the array
        return prevSports.filter((s) => s !== sport);
      }
    });
  };
  
  const handleFilterChange = (value) => {
    setFilterBy(value);
  };

  const sportsBars = {
    Football: {
      color: '#82ca9d',
      order: 0,
    },
    Basketball: {
      color: '#8884d8',
      order: 1,
    },
    Tennis: {
      color: '#2884d8',
      order: 2,
    },
    Baseball: {
      color: 'rgb(53, 102, 120)',
      order: 3,
    },
    Hockey: {
      color: 'rgb(255, 99, 132)',
      order: 4,
    },
    AmericanFootball: {
      color: '#5820c8',
      order: 5,
    },
  }

  const sortedData = Object.entries(events)
    .filter(([dateTime]) => {
      const date = new Date(dateTime);
      return (
        date.getDate() === selectedDate.getDate() &&
        date.getMonth() === selectedDate.getMonth() &&
        date.getFullYear() === selectedDate.getFullYear()
      );
    })
    .map(([dateTime, items]) => ({
      dateTime: new Date(dateTime),
      ...items.reduce((acc, { sport }) => {
        acc[getCapitalizedWord(sport.name)] = (acc[getCapitalizedWord(sport.name)] || 0) + 1;
        return acc;
      }, {}),
    }))
    .sort((a, b) => a.dateTime - b.dateTime);

  const chartData = Object.values(sortedData)
    .map(data => ({
      name: new Date(data.dateTime).getHours(),
      ...data,
    }))
    .filter(data => selectedSports.length === 0 || selectedSports.some(sport => data[sport]));

  const filteredChartData = chartData.map(item => {
    const newItem = { ...item };
    Object.keys(newItem).forEach(key => {
      if (!selectedSports.includes(key) && key !== 'name') {
        delete newItem[key];
      }
    });
    return newItem;
  });

  const mergedData = hoursData.map(hourData => {
    const matchingData = filteredChartData.find(data => data.name === hourData.name);
    return matchingData ? matchingData : hourData;
  });

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <TooltipContainer>
          <TooltipLabel>{label}:00</TooltipLabel>
            {payload.map((pld) => (
              <TooltipItem>
                <TooltipItemContainer>
                  <TooltipIcon color={pld.fill} />
                  <TooltipText>{t(pld.dataKey.toLowerCase())}</TooltipText>
                </TooltipItemContainer>
                <TooltipValue>{pld.value}</TooltipValue>
              </TooltipItem>
            ))}
            <TooltipSeparator />
            <TooltipTotalContainer>
              <TooltipTotal>Total</TooltipTotal>
              <TooltipTotalValue>{payload.reduce((acc, pld) => acc + pld.value, 0)}</TooltipTotalValue>
            </TooltipTotalContainer>
        </TooltipContainer>
      );
    }
  
    return null;
  };

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${day}/${month} ${hours}:${minutes}`;
  };

  const dateOptions = Object.keys(events)
  .sort((a, b) => new Date(a) - new Date(b))
  .reduce((acc, _date) => {
    const date = new Date(_date);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");

    const naturalDate =  `${day}/${month}`;
    if (acc[naturalDate]) {
      acc[naturalDate].push(date);
    } else {
      acc[naturalDate] = [date];
    }
    return acc;
  }, {});

  const sports = Object.keys(
    data.reduce((sports, { sport }) => {
      sports[getCapitalizedWord(sport.name)] = true;
      return sports;
    }, {})
  ).sort();

  const TableSportsPicker = () => {
    // Get sports from the current page's data before sport filtering
    const sportsInCurrentPage = (pages || [])[activePage - 1]?.reduce((acc, { sport }) => {
      acc[getCapitalizedWord(sport.name)] = true;
      return acc;
    }, {});

    // Filter available sports to only those present in current page
    const availableSports = Object.keys(sportsBars).filter(sport => sportsInCurrentPage?.[sport]);
    
    return (
      <div style={{
        display: 'flex',
        background: '#FFFFFF',
        borderRadius: '5px',
        overflow: 'hidden',
        height: '38px'
      }}>
        {availableSports.map((sport, index) => (
          <LegendContainer 
            key={`table-sport-${index}`} 
            onClick={() => handleSportClick(sport)}
            selected={selectedSports.includes(sport)}
          >
            <img 
              src={getSportIcon(sport.toLowerCase())} 
              alt={sport}
              style={{ 
                width: '24px', 
                height: '24px',
                filter: getSportIconColor(sport.toLowerCase())
              }}
            />
          </LegendContainer>
        ))}
      </div>
    );
  };

  const handlePageChange = (page) => {
    if (filterBy === 'hours') {
      // Change page by hours
      setActivePage(page);
    } else {
      // Change page by days
      const date = Object.keys(dateOptions).sort((a, b) => new Date(a) - new Date(b))[page - 1];
      setSelectedDate(new Date(dateOptions[date][0]));
      setActivePage(page);
    }
  };

  useEffect(() => {
    async function fetchEvents() {
      const response = await getUpcomingEvents();
      setEvents(response);
    }
    fetchEvents();
  }, []);

  // Memoize the sorted leagues first
  const sortedLeagues = useMemo(() => 
    getSortedLeaguesFromData(visibleData),
    [visibleData]
  );

  // Memoize the first ten leagues
  const firstTenLeagues = useMemo(() => 
    sortedLeagues.slice(0, 10).map(league => league.leagueName),
    [sortedLeagues]
  );

  const toggleLeague = useCallback((leagueName) => {
    isManualToggle.current = true;
    setExpandedLeagues(prev => {
      const newSet = new Set(prev);
      if (newSet.has(leagueName)) {
        newSet.delete(leagueName);
      } else {
        newSet.add(leagueName);
      }
      return newSet;
    });
  }, []);

  // Track filter changes
  useEffect(() => {
    filterChangeRef.current = true;
    isManualToggle.current = false;
  }, [selectedSports, selectedDate, filterBy, filterText, liquidityFilter]);

  // Update the useEffect to respect manual toggles and handle filter changes
  useEffect(() => {
    if (groupByLeague && (!isManualToggle.current || filterChangeRef.current)) {
      // Clear any existing timeouts
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      if (expandTimeoutRef.current) {
        clearTimeout(expandTimeoutRef.current);
      }

      // Debounce the filter changes
      debounceTimeoutRef.current = setTimeout(() => {
        // Set new timeout to expand leagues
        expandTimeoutRef.current = setTimeout(() => {
          if (!isManualToggle.current || filterChangeRef.current) {
            setExpandedLeagues(new Set(firstTenLeagues));
            filterChangeRef.current = false;
          }
        }, 200);
      }, 100); // Debounce for 100ms
    }

    // Cleanup function
    return () => {
      if (debounceTimeoutRef.current) {
        clearTimeout(debounceTimeoutRef.current);
      }
      if (expandTimeoutRef.current) {
        clearTimeout(expandTimeoutRef.current);
      }
    };
  }, [groupByLeague, firstTenLeagues, selectedSports, selectedDate, filterBy, filterText, liquidityFilter]);

  const handleGroupToggle = useCallback(() => {
    isManualToggle.current = false;
    filterChangeRef.current = true;
    setGroupByLeague(prev => !prev);
    setExpandedLeagues(new Set());
  }, []);

  const renderEvents = useCallback(() => {
    const eventsToRender = groupByLeague ? visibleData : visibleData?.slice(0, displayedEvents);

    if (!groupByLeague) {
      const currentTime = Date.now();
      let hasAddedSeparator = false;
      let separatorAdded = false;

      return eventsToRender?.map((event, index) => {
        const eventTime = new Date(event.startedAt).getTime();
        const isUpcoming = eventTime > currentTime;

        // Add separator before the first upcoming event
        let separator = null;
        if (isUpcoming && !separatorAdded) {
          separator = (
            <TimelineSeparator key="separator">
              <TimelineTag>{t('We are here')}</TimelineTag>
            </TimelineSeparator>
          );
          separatorAdded = true;
        }

        return (
          <React.Fragment key={`event-${index}`}>
            {separator}
            <EventCard 
              ref={index === displayedEvents - 1 ? lastEventElementRef : null}
              isUpcoming={isUpcoming}
            >
              <CardHeader>
                <CardSportIcon
                  src={getSportIcon(event.sport.name)}
                  filter={getSportIconColor(event.sport.name)}
                />
                <CardSportName>{t(event.sport.name)}</CardSportName>
              </CardHeader>
              <CardContent>
                <CardEventName>{event.name}</CardEventName>
                <CardLeagueName>
                  {event.league.name}
                  {event.league.country && ` • ${event.league.country}`}
                </CardLeagueName>
              </CardContent>
              <CardTime>
                <CardTimeIcon className="fa-regular fa-clock" />
                {formatDate(new Date(event.startedAt))}
              </CardTime>
            </EventCard>
          </React.Fragment>
        );
      });
    }

    // Auto-expand first 10 leagues when grouping is enabled and no manual toggle has occurred
    if (groupByLeague && expandedLeagues.size === 0 && (!isManualToggle.current || filterChangeRef.current)) {
      setExpandedLeagues(new Set(firstTenLeagues));
      filterChangeRef.current = false;
    }

    return sortedLeagues.map(({ leagueName, events, mostCommonSport }) => (
      <LeagueGroup key={leagueName}>
        <LeagueHeader onClick={() => toggleLeague(leagueName)}>
          <LeagueGroupName>
            <CardSportIcon
              src={getSportIcon(mostCommonSport)}
              filter={getSportIconColor(mostCommonSport)}
            />
            <div>
              <span style={{ color: '#E9FD8C' }}>{leagueName}</span>
              {events[0]?.league?.country && <span style={{ color: '#758b77' }}>{` • ${events[0].league.country}`}</span>}
            </div>
          </LeagueGroupName>
          <LeagueCount>
            {events.length} {t('events')} • {t('AvgLiquidity')}: {Math.round(events.reduce((sum, event) => sum + (event.liquidityScore || 0), 0) / events.length * 10) / 10}
          </LeagueCount>
        </LeagueHeader>
        <AnimatePresence>
          {expandedLeagues.has(leagueName) && (
            <motion.div
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={leagueVariants}
            >
              <LeagueEvents>
                {events.map((event, index) => {
                  const eventTime = new Date(event.startedAt).getTime();
                  const isUpcoming = eventTime > Date.now();
                  return (
                    <EventCard key={index} isUpcoming={isUpcoming}>
                      <CardContent>
                        <CardEventName>{event.name}</CardEventName>
                      </CardContent>
                      <CardTime>
                        <CardTimeIcon className="fa-regular fa-clock" />
                        {formatDate(new Date(event.startedAt))}
                      </CardTime>
                    </EventCard>
                  );
                })}
              </LeagueEvents>
            </motion.div>
          )}
        </AnimatePresence>
      </LeagueGroup>
    ));
  }, [groupByLeague, visibleData, displayedEvents, expandedLeagues, firstTenLeagues, sortedLeagues, toggleLeague, t]);

  return (
    <>
      <EventsContainer>
        <NavBarMain currentPage="events" history={history}/>
      <Subcontainer>
        <ChartContainer>
              <Title>{t('events')}</Title>
              <ResponsiveContainer width="100%" height={400} minWidth={300}>
                <BarChart
                  data={mergedData}
                  
                >
                  <XAxis dataKey="name" stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                  <YAxis stroke="white" axisLine={false} tickLine={false} tickMargin={5} />
                  <Tooltip content={<CustomTooltip/>} cursor={{ fill: "transparent" }} />
                  <Legend content={<CustomLegend onClick={(sport) => {
                    handleSportClick(sport);
                  }} />} />
                  <CartesianGrid strokeDasharray="3 0" vertical={false} stroke="rgba(255, 255, 255, 0.1)" />
                  {Object.entries(sportsBars).map(([sport, { color, order }]) => <Bar
                        key={sport}
                        dataKey={sport}
                        stackId="a"
                        fill={selectedSports.includes(sport) ? color : "transparent"}
                        order={order}
                      />)}
                </BarChart>
              </ResponsiveContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: 0,
                }}
              >
                <Pagination>
                  {Object.entries(dateOptions)
                  .filter(([, times]) => times.length > 5)
                  .map(([naturalDate]) => {
                    const day = selectedDate.getDate().toString().padStart(2, "0");
                    const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");

                    const naturalDateForSelcted =  `${day}/${month}`;

                    const active = naturalDate === naturalDateForSelcted;
                    return (
                      <Pagination.Item
                        key={naturalDate}
                        onClick={() => {
                          setSelectedDate(new Date(dateOptions[naturalDate][0]));
                          if (filterBy === 'days') {
                            const page = Object.keys(dateOptions).sort((a, b) => new Date(a) - new Date(b)).indexOf(naturalDate) + 1;
                            setActivePage(page)}
                          }
                        }
                        active={active}
                        linkClassName="paginationCustom"
                      >
                        {naturalDate}
                      </Pagination.Item>
                    );
                  })}
                </Pagination>
              </div>
        </ChartContainer>
          <div className="container" style={{ maxWidth: '100%', padding: '0px' }}>
          <Pagination>
            {filterOptions.map((option, index) => (
              <Pagination.Item
                key={index}
                active={filterBy === option.value}
                onClick={() => handleFilterChange(option.value)}
              >
                {getCapitalizedWord(option.label)}
              </Pagination.Item>
            ))}
          </Pagination>
            <Pagination style={{ overflowY: "auto" }}>
              {filterBy === 'hours' ? pages.map((page, index) => (
                page.length > 0 &&
                <Pagination.Item
                  key={index}
                  active={index + 1 === activePage}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {formatDate(new Date(page[0]?.startedAt))}
                </Pagination.Item>
              )) : Object.entries(dateOptions).filter(([, times]) => times.length > 5).map(([naturalDate], index) => (
                <Pagination.Item
                  key={naturalDate}
                  onClick={() => handlePageChange(index + 1)}
                  active={index + 1 === activePage}
                >
                  {naturalDate}
                </Pagination.Item>
              ))}
            </Pagination>
            <FilterContainer>
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <TotalEventsContainer>
                  <b>{visibleData?.length}</b> {t('events')}
                </TotalEventsContainer>
                <GroupButton
                  active={groupByLeague}
                  onClick={handleGroupToggle}
                >
                  <i className="fa-solid fa-layer-group" style={{ marginRight: '5px' }} />
                  {t('Group by League')}
                </GroupButton>
              </div>
              <RightContainer>
                <SportsContainer>
                  <TableSportsPicker />
                  <div className="mobile-only">
                    <LiquidityButtonContainer>
                      <LiquidityButton 
                        active={liquidityFilter}
                        onClick={() => setLiquidityFilter(!liquidityFilter)}
                      >
                        <Medal 
                          size={18} 
                          style={{ marginRight: '5px' }}
                          color={liquidityFilter ? '#111E12' : '#E9FD8C'}
                        />
                        {t('High Liquidity')}
                      </LiquidityButton>
                    </LiquidityButtonContainer>
                  </div>
                </SportsContainer>
                <div className="desktop-only">
                  <LiquidityButtonContainer>
                    <LiquidityButton 
                      active={liquidityFilter}
                      onClick={() => setLiquidityFilter(!liquidityFilter)}
                    >
                      <Medal 
                        size={18} 
                        style={{ marginRight: '5px' }}
                        color={liquidityFilter ? '#111E12' : '#E9FD8C'}
                      />
                      {t('High Liquidity')}
                    </LiquidityButton>
                  </LiquidityButtonContainer>
                </div>
                <NameContainer>
                  <NameSearchContainer>
                    <Form.Control
                      type="text"
                      value={filterText}
                      placeholder={t('FilterByText')}
                      onChange={(event) => setFilterText(event.target.value)}
                    />
                  </NameSearchContainer>
                  <Form.Label style={{ marginBottom: 0 }}><i className="fa-solid fa-magnifying-glass" /></Form.Label>
                </NameContainer>
              </RightContainer>
            </FilterContainer>
            <EventsGrid>
              {renderEvents()}
            </EventsGrid>
          </div>
        </Subcontainer>
      </EventsContainer>
    </>
  );
};
